import React from 'react';

const Feature = (): JSX.Element => {
  return (
    <>
      <section id="feature_section" className="feature_section sec_ptb_120 deco_wrap clearfix">
        <div className="container">
          <div className="feature_item">
            <div className="row align-items-center justify-content-lg-between justify-content-md-center justify-content-sm-center">
              <div className="col-lg-7 col-md-10 col-sm-9">
                <a href="/learn/javascript-algorithms-and-data-structures/basic-javascript/comment-your-javascript-code"><video src="/assets/videos/demo.mp4" autoPlay loop style={{ width: '90%', position: 'relative', top: '-30px' }} /></a>
              </div>
              <div className="col-lg-5 col-md-7 col-sm-9">
                <div className="section_title decrease_size ml--30 c_slide_in">
                  <h2 className="title_text mb-30 c_slide_in_wrap1">
                    <span className="c_slide_in_wrap2">
                      <span className="c_slide_in_wrap3">
                        Interactive Learning
                      </span>
                    </span>
                  </h2>
                  <p className="mb-50" data-aos="fade-up" data-aos-delay={200}>
                    We have invented the best mentor system to help you learn web technologies in the most interactive way possible.
                  </p>
                  <div className="child_feature_2 clearfix" data-aos="fade-up" data-aos-delay={300}>
                    <div className="item_icon icon_purple">
                      <i className="icon-ring" />
                    </div>
                    <div className="item_content">
                      <h3 className="item_title">Watch Read And Practice</h3>
                      <p className="mb-0">
                        You can complete the chapter either by watching or by reading. All depends on your mood and comfort. Also do self examine by practising live .
                      </p>
                    </div>
                  </div>
                  <div className="child_feature_2 clearfix" data-aos="fade-up" data-aos-delay={400}>
                    <div className="item_icon icon_yellow">
                      <i className="icon-verified-avatar" />
                    </div>
                    <div className="item_content">
                      <h3 className="item_title">Examine &amp; Get Certified</h3>
                      <p className="mb-0">
                        Complete all the tasks live within the video and earn the certificate.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

Feature.displayName = 'Feature';
export default Feature;
