import React from 'react';

const Process = (): JSX.Element => {
  return (
    <>
      <section id="partner_section" className="partner_section process_section clearfix" style={{ paddingBottom: '0px' }}>
        <div className="sec_ptb_60" style={{ paddingTop: '0px' }}>
          <div className="container">
            <div className="row justify-content-left mb-30">
              <div className="col-lg-10 col-md-9 col-sm-10">
                <div className="section_title">
                  <h2 className="title_text mb-0 c_slide_in is_shown"><span className="c_slide_in_wrap1"><span className="c_slide_in_wrap2"><span className="c_slide_in_wrap3">Your Journey On
                    Extraise</span></span></span></h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container line_bg" data-background="/assets/images/line_1.png" style={{ backgroundImage: 'url("/assets/images/line_1.png")' }}>
          <div className="process_item clearfix aos-init aos-animate" data-aos="fade-up" data-aos-delay={300} style={{ paddingBottom: '0px' }}>
            <div className="row align-items-start justify-content-lg-between justify-content-md-between justify-content-sm-center">
              <div className="col-lg-6 col-md-6 col-sm-7 order-last">
                <div className="item_image">
                  <div className="pricing_plan_2" style={{ marginTop: '0px' }}>
                    <div className="pricing_header text-center">
                      <h3 className="title_text text_lightblue">Programmer</h3>
                      <div className="price_text" />
                    </div>
                    <div className="info_list ul_li_block">
                      <ul className="clearfix">
                        <li>Basic Javascript<i className="fas fa-check-circle" /></li>
                        <li>ES6 <i className="fas fa-check-circle" /></li>
                        <li>Regex <i className="fas fa-check-circle" /></li>
                        <li>Debugging <i className="fas fa-check-circle" /></li>
                        <li>OOPs <i className="fas fa-check-circle" /></li>
                      </ul>
                    </div>
                    <div className="_buttons">
                      {/* <a href="/learn/javascript-algorithms-and-data-structures/basic-javascript/comment-your-javascript-code" className="btn bg_default_blue">Start</a> */}
                      <a href="/learn/javascript-algorithms-and-data-structures/" className="btn bg_default_blue">Start <i className="fal fa-long-arrow-right ml-1" /></a></div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-7">
                <div className="item_content"><span className="serial_number" style={{ marginTop: '0px' }}>01</span>
                  <h4>Javascript Algo &amp; DS</h4>
                  <p className="mb-20">A structured, flexible &amp; guided learning program for the most popular language of
                    this era which gives you ability to become a fullstack developer.<br /><br />No worries to learn again
                    if you already know. Fast forward this step by <a href="/learn/javascript-algorithms-and-data-structures/basic-javascript/comment-your-javascript-code" className="btn_text">Completing Challenges <i className="fas fa-trophy" /><i className="fal fa-long-arrow-right ml-1" /></a><br /></p>
                  {/* <div class="tag_list ul_li">
                    <ul class="clearfix">
                      <li><a href="#!">HTML</a></li>
                      <li><a href="#!">CSS</a></li>
                      <li><a href="#!">JS</a></li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="process_item clearfix aos-init aos-animate" data-aos="fade-up" data-aos-delay={100} style={{ paddingBottom: '60px' }}>
            <div className="row align-items-start justify-content-lg-between justify-content-md-between justify-content-sm-center">
              <div className="col-lg-5 col-md-5 col-sm-7">
                <div className="item_image">
                  <div className="pricing_plan_2" style={{ marginTop: '0px' }}>
                    <div className="pricing_header text-center">
                      <h3 className="title_text text_lightblue">Designer</h3>
                      <div className="price_text" />
                    </div>
                    <div className="info_list ul_li_block">
                      <ul className="clearfix">
                        <li>Basic HTML5 <i className="fas fa-check-circle" /></li>
                        <li>Basic CSS <i className="fas fa-check-circle" /></li>
                        <li>Responsive Design <i className="fas fa-check-circle" /></li>
                        <li>Flex &amp; Grid <i className="fas fa-check-circle" /></li>
                        <li>Design Principles <i className="fas fa-check-circle" /></li>
                      </ul>
                    </div>
                    <div className="_buttons">
                      {/* <a href="/learn/responsive-web-design/basic-html-and-html5/say-hello-to-html-elements" className="btn bg_default_blue">Start</a> */}
                      <a href="/learn/responsive-web-design/" className="btn bg_default_blue">Start <i className="fal fa-long-arrow-right ml-1" /></a></div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-7">
                <div className="item_content"><span className="serial_number">02</span>
                  <h4>Responsive Web Design</h4>
                  <p className="mb-0">Learn how to find issues and fix them to create a bug free application and learn how
                    to use accessibilities to create a compelling design <br /><br /><br /><i className="fas fa-check-circle" />&nbsp;&nbsp;Projects and assignments to actually implement concepts<br /><i className="fas fa-check-circle" />&nbsp;&nbsp;Topics &amp; pace of learning are optimized for your
                    needs</p>
                </div>
              </div>
            </div>
          </div>
          <div className="process_item clearfix aos-init aos-animate" data-aos="fade-up" data-aos-delay={100} style={{ marginBottom: "0" }}>
            <div className="row align-items-start justify-content-lg-between justify-content-md-between justify-content-sm-center">
              <div className="col-lg-5 col-md-5 col-sm-7 order-last">
                <div className="item_image">
                  <div className="pricing_plan_2" style={{ marginTop: '0px' }}>
                    <div className="pricing_header text-center">
                      <h3 className="title_text text_lightblue">Developer</h3>
                      <div className="price_text" />
                    </div>
                    <div className="info_list ul_li_block">
                      <ul className="clearfix">
                        <li>Bootstrap <i className="fas fa-check-circle" /></li>
                        <li>jQuery <i className="fas fa-check-circle" /></li>
                        <li>SaaS<i className="fas fa-check-circle" /></li>
                        <li>React<i className="fas fa-check-circle" /></li>
                        <li>Redux<i className="fas fa-check-circle" /></li>
                      </ul>
                    </div>
                    <div className="_buttons">
                      {/* <a href="/learn/front-end-development-libraries/bootstrap/use-responsive-design-with-bootstrap-fluid-containers" className="btn bg_default_blue">Start</a> */}
                      <a href="/learn/front-end-development-libraries/" className="btn bg_default_blue">Start <i className="fal fa-long-arrow-right ml-1" /></a></div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-7">
                <div className="item_content"><span className="serial_number">03</span>
                  <h4>Industry-tested curriculum to make you job ready</h4>
                  <p className="mb-20">Take the help from our experts with your career choices &amp; guidance <br /><br /><a href="https://discord.gg/ZdWw8ezf" target={'_blank'} className="btn_text">Join Our Premium Discord Community <i className="fas fa-messages" /><i className="fal fa-long-arrow-right ml-1" /></a></p>
                  <div className="tag_list ul_li">
                    <ul className="clearfix">
                      <li><a href="#!">Bootstrap</a></li>
                      <li><a href="#!">React</a></li>
                      <li><a href="#!">D3</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="process_item clearfix aos-init aos-animate" data-aos="fade-up" data-aos-delay={100} style={{ paddingBottom: '60px' }}>
            <div className="row align-items-start justify-content-lg-between justify-content-md-between justify-content-sm-center">
              <div className="col-lg-5 col-md-5 col-sm-7">
                <div className="item_image">
                  <div className="pricing_plan_2" style={{ marginTop: '0px' }}>
                    <div className="pricing_header text-center">
                      <h3 className="title_text text_lightblue">Interview Prep.</h3>
                      <div className="price_text" />
                    </div>
                    <div className="info_list ul_li_block">
                      <ul className="clearfix">
                        <li>Data Structure<i className="fas fa-check-circle" /></li>
                        <li>Algorithms<i className="fas fa-check-circle" /></li>
                        <li>Project Euler<i className="fas fa-check-circle" /></li>
                        <li>Rosetta Code<i className="fas fa-check-circle" /></li>
                      </ul>
                    </div>
                    <div className="_buttons">
                      {/* <a href="/learn/coding-interview-prep/algorithms/find-the-symmetric-difference" className="btn bg_default_blue">Start</a> */}
                      <a href="/learn/coding-interview-prep/" className="btn bg_default_blue">Start <i className="fal fa-long-arrow-right ml-1" /></a></div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-7">
                <div className="item_content"><span className="serial_number">04</span>
                  <h4>Coding Interview Program </h4>
                  <p className="mb-0">Clear Interviews of the top rated Tech Companies<br /><br />This Program contains 500+
                    hands on exercises for you to crack interview @ Google, Microsoft, Meta ...<br /><br /><i className="fas fa-check-circle" /> &nbsp;&nbsp;Learn how to solve tricky questions of time and space
                    complexity<br /><i className="fas fa-check-circle" /> &nbsp;&nbsp;Learn solving a problem asked in big
                    Techs</p>
                </div>
              </div>
            </div>
          </div>
          <div className="process_item clearfix aos-init aos-animate" data-aos="fade-up" data-aos-delay={100}>
            <div className="row align-items-center justify-content-lg-between justify-content-md-between justify-content-sm-center">
              <div className="col-lg-5 col-md-5 col-sm-7 order-last">
                <div className="item_image"><img src="/assets/images/process/img_5.png" alt="image_not_found" />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-7">
                <div className="item_content"><span className="serial_number">05</span>
                  <h3 className="item_title">Grab Certificate</h3>
                  <p className="mb-0">Yes! You will be certified for this course on submission of your assignment.<br /><br /><i className="fas fa-check-circle" /> &nbsp;&nbsp;​Receive an instructor signed certificate with
                    institution’s logo to verify your achievements and increase your job prospects<br /><br /><i className="fas fa-check-circle" /> &nbsp;&nbsp;You will receive additional bonuses at the end of the
                    Curriculum</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

Process.displayName = 'Process';
export default Process;
