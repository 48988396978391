import React from 'react';
import { useEffect } from 'react';

const Students = (): JSX.Element => {

  useEffect(() => {
    // console.log($);
    // $('#testimonial_carousel_2').owlCarousel({
    //     nav:true,
    //     margin:0,
    //     loop:true,
    //     dots:false,
    //     smartSpeed:1000,
    //     responsive:{
    //     0:{
    //         items:1
    //     },
    //     880:{
    //         items:2
    //     },
    //     991:{
    //         items:2
    //     },
    //     1000:{
    //         items:2
    //     }
    //     }
    // });
  })

  return (
    <>
      <section id="testimonial_section" className="testimonial_section clearfix" style={{ paddingBottom: "120px" }}>
        <div className="container">
          <div className="section_title text-center increase_size mb-80 c_slide_in">
            <h3 className="sub_title mb-15">
              <span className="c_slide_in_wrap1">
                <span className="c_slide_in_wrap2">
                  <span className="c_slide_in_wrap3">
                    Students Love
                  </span>
                </span>
              </span>
            </h3>
            <h2 className="title_text mb-0">
              <span className="c_slide_in_wrap1">
                <span className="c_slide_in_wrap2">
                  <span className="c_slide_in_wrap3">
                    Love from our Students
                  </span>
                </span>
              </span>
            </h2>
          </div>
          <div id="testimonial_carousel_2" className="testimonial_carousel_2 owl-carousel arrow_top_right owl-theme" data-aos="fade-up" data-aos-delay={300}>
            <div className="item">
              <div className="testimonial_item" data-background="assets/images/shapes/shape_20.png">
                <div className="admin_wrap mb-30 clearfix">
                  <div className="rating_star ul_li_right">
                    <ul className="clearfix">
                      <li><i className="fas fa-star" /></li>
                      <li><i className="fas fa-star" /></li>
                      <li><i className="fas fa-star" /></li>
                      <li><i className="fas fa-star" /></li>
                      <li><i className="fas fa-star" /></li>
                    </ul>
                  </div>
                  {/* <div className="thumbnail_image">
                    <img src="https://media-exp1.licdn.com/dms/image/C5603AQHkrcg-X63DvQ/profile-displayphoto-shrink_200_200/0/1641294016806?e=2147483647&v=beta&t=est779NKxntnej1kVLJ8WUcWYC7Y4baP8aDddaae7fA" alt="image_not_found" />
                  </div> */}
                  <div className="admin_content">
                    <h3 className="admin_name">Mr. Ankit Singh</h3>
                    <span className="admin_title">UI/UX designer</span>
                  </div>
                </div>
                <p className="mb-0">
                  For those asking, I'm taking the Frontend Development course by @Extraise and loving it so far!
                </p>
              </div>
            </div>
            <div className="item">
              <div className="testimonial_item" data-background="assets/images/shapes/shape_20.png">
                <div className="admin_wrap mb-30 clearfix">
                  <div className="rating_star ul_li_right">
                    <ul className="clearfix">
                      <li><i className="fas fa-star" /></li>
                      <li><i className="fas fa-star" /></li>
                      <li><i className="fas fa-star" /></li>
                      <li><i className="fas fa-star" /></li>
                      <li><i className="fas fa-star" /></li>
                    </ul>
                  </div>
                  {/* <div className="thumbnail_image">
                    <img src="assets/images/meta/img_1.png" alt="image_not_found" />
                  </div> */}
                  <div className="admin_content">
                    <h3 className="admin_name">Mr. Gaurav Kataria</h3>
                    <span className="admin_title">Full Stack Developer</span>
                  </div>
                </div>
                <p className="mb-0">
                  I liked the interactive way of learning through extraise, and never found it's alternative. Best Decision!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

Students.displayName = 'Students';
export default Students;
