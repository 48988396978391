import React from 'react';
import { apiLocation } from '../../../../../config/env.json';

const Banner = (props: any): JSX.Element => {
  return (
    <>
      <section id="banner_section" className="banner_section software_banner deco_wrap d-flex align-items-center text-white clearfix">
        <div className="container">
          <div className="row align-items-center justify-content-lg-between">
            <div className="col-lg-5 order-last">
              <div className="banner_image scene">
                <div className="big_image">
                  <div className="layer" data-depth="0.2">
                    <div data-aos="fade-up" data-aos-delay={300} style={{ textAlign: "center" }}>
                      <img src="https://cdn.pixabay.com/photo/2019/10/09/07/28/development-4536630_1280.png" alt="image_not_found" style={{ maxHeight: "400px" }}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="banner_content c_slide_in">
                <h1 className="title_text mb-30 text_effect_wrap text_effect_wrap">
                  <span className="text_effect text_effect_left">
                    A Career Boosting Program
                  </span>
                  <span className="text_effect text_effect_left">
                    to level up your skills !..
                  </span>
                </h1>
                <p className="mb-50" data-aos="fade-up" data-aos-delay={200}>
                  The Perfect Non Textbook Tutorial with Live Tasks Assignment to Start Your Career in Web Technologies!
                </p>
                <div className="btns_group ul_li" data-aos="fade-up" data-aos-delay={300}>
                  <ul className="d-flex align-items-center clearfix">
                    <li><a href={ apiLocation + '/signin' } className="btn btn_border border_white">Join</a></li>
                    <li><a href="/learn/" className="btn_text">Try for free <i className="fal fa-long-arrow-right ml-1" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="deco_image bg_shape_1" data-background="/assets/images/banner/10_software/bg_1.png" />
        <div className="deco_image bg_shape_2" data-background="/assets/images/banner/10_software/bg_2.png" />
        <div className="deco_image bg_shape_3" data-background="/assets/images/banner/10_software/bg_3.png" data-aos="zoom-out" data-aos-delay={100} />
        <div className="deco_image shape_1" data-aos="fade-left" data-aos-delay={500}>
          <img src="/assets/images/banner/10_software/shape_1.png" alt="shape_not_found" />
        </div>
        <div className="deco_image shape_2" data-aos="fade-right" data-aos-delay={700}>
          <img src="/assets/images/banner/10_software/shape_2.png" alt="shape_not_found" />
        </div>
        <div className="deco_image cloud_1" data-aos="fade-up" data-aos-delay={500}>
          <img src="/assets/images/banner/10_software/cloud_1.png" alt="shape_not_found" />
        </div>
        <div className="deco_image cloud_2" data-aos="fade-up" data-aos-delay={700}>
          <img src="/assets/images/banner/10_software/cloud_1.png" alt="shape_not_found" />
        </div>
      </section>
    </>
  );
};

Banner.displayName = 'Banner';
export default Banner;
