import { Grid } from '@freecodecamp/react-bootstrap';
import React, { ReactElement } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

import AsSeenIn from './components/as-seen-in';
import Certifications from './components/certifications';
import LandingTop from './components/landing-top';
import Testimonials from './components/testimonials';
import Students from './components/students';

import Header from './components/header';

import './landing.css';
import Banner from './components/banner';
import Feature from './components/feature';
import Process from './components/process';
import Partner from './components/partner';
import Try from './components/try';
import Footer from './components/footer';
import Investors from './components/investors';

function Landing(): ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <Header signin={true} />
      <main>
        <Banner />
        <Feature />
        <Process />
        <Partner />
        <Students />
        {/* <Try /> */}
        {/* <Investors/> */}
      </main>
      <Footer />
    </>
  );
}

Landing.displayName = 'Landing';
export default Landing;
