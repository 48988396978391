import React from 'react';

import { apiLocation } from '../../../../../config/env.json';

const Partner = (): JSX.Element => {
  return (
    <>
      <section id="partner_section" className="partner_section text-center sec_ptb_120 clearfix" style={{ paddingTop: '40px' }}>
        <div className="container">
          <div className="row justify-content-center mb-30 aos-init aos-animate" data-aos="fade-up" data-aos-delay={100} style={{ margin: '0px' }}>
            <div className="col-lg-10 col-md-9 col-sm-10">
              <div className="section_title">
                <h2 className="title_text mb-0">Land Your Dream Job @</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center aos-init aos-animate" data-aos="fade-up" data-aos-delay={300}>
            <div className="partner_logo col-lg-2"><a href="#!"><svg width={85} height={24} xmlns="http://www.w3.org/2000/svg" aria-label="Netflix" className="fill-current">
              <path d="M11.7 22.4c-1.3.3-2.6.3-4 .5l-4-12.5v13L0 24V0h3.4l4.7 13.6V0h3.6v22.4zm7-13.6h4.9v3.7h-4.9v5.6l6.4-.4v3.6l-10 .8V0h10v3.8h-6.3v5zm19.8-5h-3.8V21h-3.6V3.7h-3.7V0h11v3.8zm5.8 4.7h4.9v3.8h-5v8.5h-3.4V0h10v3.8h-6.5v4.7zm12.3 9l6.1.3v3.7L53 21V0h3.6v17.5zm9.2 4.3l3.5.3V0h-3.6v21.8zM85 0l-4.5 11.4L85 24l-4-.7-2.6-7-2.6 6.4-3.9-.5 4.6-11L72.3 0h4l2.3 6.3L81 0H85z">
              </path>
            </svg></a></div>
            <div className="partner_logo col-lg-2"><a href="#!"><svg width={111} height={23} xmlns="http://www.w3.org/2000/svg" aria-label="Facebook" className="fill-current">
              <path d="M57.1 11.3a5 5 0 00-2.2.6v6.8h1.6c2.2 0 2.5-1 2.5-2.3V13c0-1-.4-1.8-1.9-1.8zm-14.4-.4c-1.5 0-1.8.8-1.8 1.9v.5h3.7v-.5c0-1-.4-1.9-1.9-1.9zm-28 7.1c0 .8.5 1.2 1.3 1.2.9 0 1.4-.2 2.1-.5V17h-2c-1 0-1.3.2-1.3 1zm56.5-6.7c-1.6 0-2 .8-2 1.8V17c0 1 .4 1.8 2 1.8 1.5 0 2-.8 2-1.8V13c0-1-.5-1.8-2-1.8zm-63.8 11H2.9V11.4H.8V7.8H3V5.6C3 2.6 4.2.8 7.7.8h3v3.7H9C7.5 4.5 7.4 5 7.4 6v1.8h3.3l-.4 3.7h-3v10.7zm15.1 0h-3.7l-.1-1a8.5 8.5 0 01-4.2 1c-2.7 0-4.2-1.7-4.2-4.2 0-3 1.7-4 4.7-4h3v-.7c0-1.5 0-2-2.4-2h-3.8l.4-3.6h4.1c5.1 0 6.2 1.6 6.2 5.7v8.7zm12.6-10.5c-2.3-.4-3-.5-4-.5-2 0-2.7.4-2.7 2.1v3.2c0 1.7.6 2.1 2.6 2.1 1.1 0 1.8 0 4.1-.5v3.6c-2 .5-3.3.6-4.4.6-4.8 0-6.7-2.5-6.7-6.1v-2.6c0-3.6 2-6.1 6.7-6.1 1 0 2.4.1 4.4.6v3.6zM49 16.3h-8.1v.3c0 1.7.6 2.1 2.6 2.1 1.7 0 2.8 0 5.1-.5v3.6c-2.2.5-3.3.6-5.5.6-4.8 0-6.7-2.5-6.7-6.1v-3c0-3.1 1.4-5.7 6.3-5.7s6.3 2.6 6.3 5.7v3zm14.4 0c0 3.6-1 6.1-7 6.1-2.2 0-3.5-.2-6-.6v-20l4.5-.6v7c1-.4 2.2-.6 3.3-.6 4.5 0 5.2 2 5.2 5.2v3.6zm14.2.1c0 3-1.2 6-6.4 6s-6.5-3-6.5-6v-2.9c0-3 1.3-5.9 6.5-5.9s6.4 3 6.4 6v2.8zm14.2 0c0 3-1.2 6-6.4 6s-6.5-3-6.5-6v-2.9c0-3 1.3-5.9 6.5-5.9s6.4 3 6.4 6v2.8zm14.6 5.8h-4.8l-4-6.8v6.8H93V2l4.4-.7v13l4.1-6.4h4.8l-4.4 7 4.4 7.4zm-21-11c-1.5 0-2 1-2 2v3.7c0 1 .5 1.8 2 1.8s2-.8 2-1.8V13c0-1-.5-1.8-2-1.8zm23.5 8.5c.8 0 1.4.6 1.4 1.3 0 .8-.6 1.4-1.3 1.4a1.4 1.4 0 01-1.4-1.4c0-.7.6-1.3 1.4-1.3zm0 .2c-.6 0-1 .5-1 1.1 0 .7.5 1.2 1 1.2.6 0 1.1-.5 1.1-1.2 0-.6-.5-1.1-1-1.1zm-.2 2h-.3v-1.6h.5l.5.1v.3c0 .2 0 .3-.2.4.1 0 .2.1.3.4v.3h-.2a1.1 1.1 0 01-.1-.4c0-.1-.1-.2-.3-.2h-.2v.6zm0-.9h.2c.2 0 .3 0 .3-.3 0-.1 0-.2-.3-.2h-.2v.5z">
              </path>
            </svg></a></div>
            <div className="partner_logo col-lg-2"><a href="#!"><svg width={36} height={43} xmlns="http://www.w3.org/2000/svg" aria-label="Apple" className="fill-current">
              <path d="M35.3 33.5a23.4 23.4 0 01-2.3 4.1 21.1 21.1 0 01-3 3.6 5.7 5.7 0 01-3.8 1.7c-1 0-2.2-.3-3.5-.8a10 10 0 00-3.8-.9c-1.2 0-2.5.3-4 .9-1.3.5-2.4.8-3.3.9-1.3 0-2.6-.6-3.9-1.8a22 22 0 01-3-3.7A25.8 25.8 0 011.3 31 24 24 0 010 23.2c0-2.9.6-5.4 1.9-7.5a11 11 0 013.9-4 10.4 10.4 0 015.2-1.4c1 0 2.4.3 4 1 1.8.6 2.8.9 3.3.9.4 0 1.6-.4 3.6-1.1 2-.7 3.5-1 4.9-.9 3.6.3 6.3 1.7 8 4.3a9 9 0 00-4.7 8.2 9.1 9.1 0 003 6.8 9.7 9.7 0 002.9 2 32.3 32.3 0 01-.8 2zM27 .9a9.2 9.2 0 01-2.3 6c-2 2.2-4.2 3.5-6.6 3.2a6.7 6.7 0 01-.1-.8c0-2 .9-4.2 2.5-6 .8-1 1.8-1.7 3-2.3a9 9 0 013.4-1l.1.9z">
              </path>
            </svg></a></div>
            <div className="partner_logo col-lg-2"><a href="#!"><svg width={97} height={32} xmlns="http://www.w3.org/2000/svg" aria-label="Google" className="fill-current">
              <path d="M41.6 16.8a7.8 7.8 0 01-8 7.9c-4.4 0-8-3.4-8-8 0-4.5 3.6-7.8 8-7.8s8 3.3 8 7.9zm-3.5 0c0-2.9-2-4.8-4.5-4.8-2.4 0-4.5 2-4.5 4.8s2 4.8 4.5 4.8 4.5-2 4.5-4.8zm20.7 0c0 4.5-3.6 7.9-8 7.9s-8-3.4-8-8a7.8 7.8 0 018-7.8c4.4 0 8 3.3 8 7.9zm-3.5 0c0-2.9-2-4.8-4.5-4.8-2.4 0-4.5 2-4.5 4.8s2.1 4.8 4.5 4.8 4.5-2 4.5-4.8zm20-7.4v14.1c0 5.8-3.4 8.2-7.5 8.2a7.6 7.6 0 01-7-4.6l3-1.3a4.4 4.4 0 004 2.8c2.6 0 4.2-1.6 4.2-4.6v-1.1c-.9 1-2.4 1.8-4.3 1.8a7.8 7.8 0 01-7.6-7.9c0-4.5 3.6-8 7.6-8 2 0 3.4 1 4.2 1.8h.1V9.4h3.4zm-3 7.4c0-2.8-1.9-4.8-4.3-4.8s-4.4 2-4.4 4.8c0 2.7 2 4.7 4.4 4.7 2.4 0 4.3-2 4.3-4.7zm8.5-15.7v23h-3.4v-23zm13.3 18.3l2.8 1.8a8 8 0 01-6.7 3.5 7.8 7.8 0 01-7.9-8c0-4.6 3.4-7.8 7.5-7.8s6.2 3.2 6.8 5l.4.9-10.7 4.4a4 4 0 004 2.4c1.7 0 3-1 3.8-2.2zm-8.3-2.9l7-2.9c-.3-1-1.5-1.7-2.9-1.7a4.3 4.3 0 00-4.1 4.6zm-73.1-1.8v-3.3H24l.1 2a11 11 0 01-2.9 7.8c-2.2 2.3-5 3.5-8.6 3.5C5.9 24.7 0 19.2 0 12.4S6 .2 12.7.2c3.8 0 6.4 1.4 8.5 3.3L18.8 6a8.7 8.7 0 00-6.1-2.4 8.8 8.8 0 00-8.9 9c0 4.8 3.9 8.8 8.9 8.8a8.3 8.3 0 006.2-2.4 6.9 6.9 0 001.9-4.2h-8.1z">
              </path>
            </svg></a></div>
            <div className="partner_logo col-lg-2"><a href="#!"><svg width={81} height={25} xmlns="http://www.w3.org/2000/svg" aria-label="Amazon" className="fill-current">
              <g fillRule="evenodd">
                <path d="M50.3 19.5A30.3 30.3 0 0132.9 25a31.1 31.1 0 01-21.3-8.4c-.4-.4 0-1 .5-.6a42 42 0 0021.2 5.8c5.3 0 11-1.1 16.3-3.4.8-.4 1.4.5.7 1.1">
                </path>
                <path d="M52.2 17.2c-.6-.8-4-.4-5.5-.2-.5 0-.5-.3-.1-.6 2.7-2 7.1-1.4 7.6-.7.5.6-.1 5.1-2.7 7.3-.3.3-.7.1-.5-.3.5-1.4 1.8-4.7 1.2-5.5M46.8 2.7V.8a.5.5 0 01.5-.4h8.2c.3 0 .5.2.5.4v1.6c0 .3-.2.7-.6 1.2L51 10a9 9 0 014.7 1c.3.2.4.5.5.7v2c0 .3-.3.6-.6.5a9.3 9.3 0 00-8.8 0c-.3.1-.6-.2-.6-.4v-2c0-.3 0-.8.3-1.3l5-7.2h-4.3a.5.5 0 01-.5-.5m-30 11.8h-2.5a.5.5 0 01-.5-.5V1c0-.3.2-.5.5-.5h2.3c.3 0 .5.2.5.4v1.8C17.7.9 19 0 20.4 0c1.6 0 2.6.8 3.3 2.5A3.6 3.6 0 0127 0c1.1 0 2.2.5 3 1.5.7 1 .6 2.7.6 4V14a.5.5 0 01-.5.5h-2.5a.5.5 0 01-.5-.5V7 4.5c-.2-.8-.8-1-1.5-1-.6 0-1.3.3-1.5 1-.3.7-.2 1.8-.2 2.5v7a.5.5 0 01-.5.5H21a.5.5 0 01-.5-.5V7c0-1.5.3-3.6-1.5-3.6s-1.8 2-1.8 3.6v7a.5.5 0 01-.4.5M63 0c3.7 0 5.7 3.3 5.7 7.4 0 4-2.2 7.2-5.7 7.2-3.7 0-5.7-3.2-5.7-7.3s2-7.3 5.7-7.3m0 2.7c-1.8 0-2 2.6-2 4.2s0 5 2 5 2-2.7 2-4.4c0-1.1 0-2.5-.3-3.5s-.9-1.3-1.7-1.3m10.5 11.7h-2.5a.5.5 0 01-.4-.5V.8a.5.5 0 01.5-.4h2.3a.5.5 0 01.4.4v2h.1C74.7 1 75.7 0 77.4 0c1.1 0 2.2.4 3 1.6.6 1 .6 2.8.6 4V14a.5.5 0 01-.5.5H78a.5.5 0 01-.4-.5V7c0-1.4.1-3.5-1.6-3.5-.6 0-1.2.4-1.5 1a6 6 0 00-.4 2.5v7a.5.5 0 01-.4.6M40.3 8.2c0 1 0 1.8-.5 2.7-.4.7-1 1.2-1.7 1.2-1 0-1.6-.8-1.6-1.9 0-2.2 2-2.6 3.8-2.6v.6m2.5 6.2a.5.5 0 01-.6 0c-.8-.6-1-1-1.4-1.6-1.4 1.4-2.4 1.8-4.2 1.8-2.1 0-3.7-1.3-3.7-4 0-2 1-3.4 2.6-4.1 1.4-.6 3.3-.8 4.8-1v-.3c0-.6 0-1.3-.3-1.8s-1-.7-1.5-.7c-1 0-1.8.5-2 1.6 0 .2-.2.4-.4.4l-2.4-.2c-.2 0-.5-.3-.4-.6.5-3 3.2-3.9 5.6-3.9 1.2 0 2.8.3 3.8 1.3 1.2 1.1 1 2.7 1 4.4v4c0 1.2.6 1.7 1 2.4.2.2.2.5 0 .6l-2 1.7M7.5 8.2c0 1 0 1.8-.5 2.7-.4.7-1 1.2-1.7 1.2-1 0-1.5-.8-1.5-1.9 0-2.2 1.9-2.6 3.7-2.6v.6m2.5 6.2a.5.5 0 01-.6 0c-.8-.6-1-1-1.4-1.6-1.4 1.4-2.4 1.8-4.1 1.8-2.1 0-3.8-1.3-3.8-4 0-2 1.1-3.4 2.7-4.1 1.3-.6 3.2-.8 4.7-1v-.3c0-.6 0-1.3-.3-1.8s-1-.7-1.4-.7c-1 0-1.8.5-2 1.6 0 .2-.2.4-.5.4L.8 4.5c-.2-.1-.4-.3-.4-.6C1 1 3.6 0 6 0c1.3 0 2.8.3 3.8 1.3C11 2.4 10.9 4 10.9 5.7v4c0 1.2.5 1.7 1 2.4v.6l-2 1.7">
                </path>
              </g>
            </svg></a></div>
          </div><a href={ apiLocation + '/signin' } className="btn bg_default_purple" style={{ marginTop: '50px' }}>Get Enrolled Now</a>
        </div>
      </section>
    </>
  );
};

Partner.displayName = 'Partner';
export default Partner;
